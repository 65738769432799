<template>
    <div class="wrap">
        <img src="@/assets/top.png" width="16" alt />
    </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
    position: fixed;
    z-index: 999;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: rgba(0, 138, 255, 1);
    border: 6px solid #7ac0fc;
    right: 4%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    cursor: pointer;
}
</style>