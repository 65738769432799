<template>
    <div class="wrap">
        <span class="title">{{this.title}}</span>
        <span class="content">{{this.content}}</span>
    </div>
</template>
<script>
export default {
    props: ['title', 'content']
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    display:flex;
    align-items: center;
    flex-direction: column;
    .title {
        font-size: 38px;
        font-weight: 500;
        color: rgba(0, 138, 255, 1);
        margin-top: 130px;
    }
    .content {
        font-size: 24px;
        font-weight: 400;
        color: rgba(27, 1, 2, 1);
        margin-bottom: 50px;
    }
}
</style>
