<template>
    <div class="wrap">
        <img :src="bannerObj.img" width="100%" :height="this.curHeight" class="fill" alt />
        <div class="middle">
            <div class="text animated fadeInLeft fast">
                <span class="title">{{bannerObj.title}}</span>
                <span class="desc">{{bannerObj.desc}}</span>
                <div class="bar_wrap">
                    <div class="bar"></div>
                    <span>{{bannerObj.culture}}</span>
                </div>
            </div>
            <div class="img animated fadeInDown">
                <img src="../assets/banner_circle.png" width="470" alt />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['bannerObj'],
    data() {
        return {
            curHeight: ''
        }
    },
    beforeMount() {
        this.curHeight =
            document.documentElement.clientHeight || document.body.clientHeight
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    position: relative;
    overflow: hidden;
    .fill {
        object-fit: fill;
    }
    .middle {
        max-width: 1024px;
        margin: 0 auto;
        .text {
            position: absolute;
            top: 50%;
            font-size: 32px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
            display: flex;
            flex-direction: column;
            transform: translate3d(0, -50%, 0);
            .title {
                margin-bottom: 24px;
            }
            .desc {
                margin-bottom: 24px;
            }
            .bar_wrap {
                font-size: 16px;
                display: flex;
                align-items: center;
                .bar {
                    width: 5px;
                    height: 29px;
                    background: rgba(255, 255, 255, 1);
                    margin-right: 16px;
                }
            }
        }
        .img {
            position: absolute;
            top: 25%;
            left: 60%;
            transform: translate3d(0, -50%, 0);
        }
    }
}
</style>