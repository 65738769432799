<template>
    <div class="wrap">
        <div class="header">
            <el-col :span="6">
                <div class="grid-content bg-purple logo_wrap">
                    <img src="@/assets/logo.png" class="logo" width="90" height="76" alt />
                </div>
            </el-col>
            <el-col :span="18">
                <div class="grid-content bg-purple-light nav_wrap">
                    <ul class="nav">
                        <router-link tag="li" to="/home">产品</router-link>
                        <router-link tag="li" to="/about">关于我们</router-link>
                        <router-link tag="li" to="/news">新闻中心</router-link>
                    </ul>
                </div>
            </el-col>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    height: 90px;
    background-color: #008aff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    .header {
        padding-top: 6px;
        box-sizing: border-box;
        max-width: 1024px;
        margin: 0 auto;
        color: #fff;
        display: flex;
        align-items: center;
        .logo_wrap {
            display: flex;
            align-items: center;
        }
        .nav_wrap {
            float: right;
            .nav {
                display: flex;
                list-style: none;
                li {
                    margin-right: 48px;
                    height: 100%;
                    opacity: 0.6;
                    cursor: pointer;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .router-link-active {
                    opacity: 1;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>