<template>
    <div class="wrap">
        <div class="contnet">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <ul class="items">
                            <li
                                v-for="(item,index) of items"
                                :key="index"
                                class="item"
                                :class="{beian: item.title === '备案号:'}"
                                @click="href(item)"
                            >
                                <span class="title">{{item.title}}</span>
                                <span
                                    class="content"
                                    :class="[index === 1 ? 'active' : '']"
                                >{{item.content}}</span>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <!-- <el-col :span="1">
                    <div class="grid-content bg-purple-light code bar">
                        <div class="bar1"></div>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="grid-content bg-purple-light code">
                        <span>微信公众号</span>
                        <img src="../assets/code.png" width="160" alt />
                    </div>
                </el-col>-->
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    title: '传真:',
                    content: '0573-83331659'
                },
                {
                    title: '公司电话:',
                    content: '0573-82828265'
                },
                {
                    title: '邮箱:',
                    content: 'jin0573@sohu.com'
                },
                {
                    title: '地址:',
                    content:
                        '浙江省嘉兴市南湖区嘉兴软件园汇信路153号15楼大虫科技'
                },
                {
                    title: '备案号:',
                    content: '浙ICP备2022005221号-1'
                }
            ]
        }
    },
    methods: {
        href(item) {
            if (item.title === '备案号:') {
                window.open('https://beian.miit.gov.cn/')
            } else {
                return
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    width: 100%;
    background-color: #e8e8e8;
    padding: 50px 0;
    box-sizing: border-box;
    margin-top: 116px;
    .contnet {
        max-width: 1024px;
        margin: 0 auto;
        & .active {
            color: #008aff;
            font-size: 24px;
        }
        .items {
            list-style: none;
            color: #333333;
            .item {
                margin-bottom: 24px;
                .title {
                    margin-right: 16px;
                }
            }
            .beian{
                cursor: pointer;
            }
        }
        .code {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                font-size: 20px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-bottom: 24px;
            }
        }
        .bar {
            .bar1 {
                width: 2px;
                height: 210px;
                border-radius: 2px;
                background-color: #b9b9b9;
            }
        }
    }
}
</style>